
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
.card {
  padding: hz-spacing(s);
}

.uspsWrap {
  margin-bottom: hz-spacing(l);
}

.heading {
  margin-bottom: hz-spacing(xs);
}

.videoPlay {
  width: 100%;
}

.info,
.videoWrap,
.awards,
.brands {
  margin-top: hz-spacing(l);
  margin-bottom: hz-spacing(l);
}

.brandsImages {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: hz-spacing(m);
}

.brandImage {
  max-width: 100px;
  max-height: 70px;
}
