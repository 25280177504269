
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

$profileImageHeight: 71px;
$profileImageWidth: 71px;

.card {}

.cardSection {
  display: flex;
  padding: hz-spacing(s);
  flex-direction: row;

  &-address {
    display: flex;
    padding: hz-spacing(s);
  }
}

.alignCenter {
  align-items: center;
}

.dsaApprovedIcon {
  padding: hz-spacing(xs);
  margin-right: hz-spacing(xs);
}

.cardSection-address + .cardSection {
  padding: 0 hz-spacing(s) hz-spacing(s);
}

.infoWrap {
  margin-bottom: hz-spacing(xs);
  display: flex;
  align-items: center;

  &-link {
    font-weight: 400;
  }

  &-icon {
    margin-right: hz-spacing(xxs);
  }

  &-email {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  button {
    text-align: left;
  }
}

.infoWrapWithMultiline {
  align-items: start;
}

.infoMultiline {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: hz-spacing(xxs)
}

.locationWrap {
  display: flex;
  &-icon {
    margin-right: hz-spacing(xs);
    margin-left: -2px;
  }
}

.openingHoursWrap {
  @include hz-textStyle(body-regular);
  display: flex;
  flex-direction: column;

  .openingHours {
    display: flex;
  }

  .linkWrapper {
    margin-left: hz-spacing(l);
  }
}

.openingHoursPopupWrap {
  @include hz-textStyle(body-regular);
  padding: hz-spacing(s) hz-spacing(l) hz-spacing(m) hz-spacing(l);

  &-item {
    display: flex;
    width: 200px;
    margin: 0 auto;
    padding-top: hz-spacing(xxs);
  }

  &-day {
    @include hz-textStyle(body-regular-strong);
    flex: 1;
  }
  &-time {
    flex: 1;
  }
}

.sellerTermsAndConditionsWrap {
  @include hz-textStyle(body-regular);
  padding: hz-spacing(s) hz-spacing(l) hz-spacing(m) hz-spacing(l);
  border-top: solid hz-border(standard) hz-color(border-light);
}

.sellerCompanyLegalInfo {
  @include hz-textStyle(body-regular);
  width: 100%;
  &-label {
    @include hz-textStyle(body-regular-strong);
    margin-bottom: hz-spacing(xs);
  }
  &-item {
    display: flex;
  }
  &-itemLabel {
    flex: 1;
  }
  &-itemContent {
    flex: 0.8;
  }
  &-link {
    margin-top: hz-spacing(xs);
  }
}

.sellerAddress {
  @include hz-textStyle(body-regular);
  display: flex;
  flex-direction: column;
}

.profileImageWrap {
  margin-right: hz-spacing(m);
  @include hz-breakpoint-up(m) {
    display: none;
  }
  @include hz-breakpoint-up(l) {
    display: block;
  }
}

.profileImage {
  height: $profileImageHeight;
  width: $profileImageWidth;
}

.mapContainer {
  width: 100%;
  height: 250px;

  @include hz-breakpoint-up(m) {
    height: 181px;
  }
}

.wrap {
  margin-bottom: hz-spacing(xs);
}

.ratingWrap {
  padding: hz-spacing(s);
  border-bottom: 1px solid hz-color(border-light);

  span {
    @include hz-textStyle(body-regular-strong);
    color: hz-color(signal-action-default);
    cursor: pointer;
  }
}

.textRight {
  text-align: right;
}

.review p {
  margin-bottom: hz-spacing(xs);
}

.salesRepsSection {
  padding: 0;
}

.salesRepsSection > ul {
  padding: hz-spacing(xs) hz-spacing(s);
}

.salesRepsSection li > div{
  margin-right: hz-spacing(xxs);
}
.salesRepsSection div > span:nth-child(2){
  @include hz-textStyle(body-small);
}
